const textVariables = require('./textVariables');

const channelConfig = {
  id: 'readshop',
  name: 'ReadShop',
  brand: 'readshop',
  locale: 'nl-nl',
  gtm: 'GTM-MTS56R9',
  clangOrigin: 'e.readshop.nl',
  sitemap: '/waroot/sitemap-readshop_nl.xml',
  imageBaseURL: 'https://prdapi.readshop.nl',
  devProxy: {
    base: 'http://localhost:3000',
    images: 'https://prdapi.readshop.nl',
  },
  api: {
    be: 'https://local.bruna.nl',
    local: 'https://testapi.readshop.nl',
    dev: 'https://devapi.readshop.nl',
    devstag: 'https://devstagapi.readshop.nl',
    test: 'https://testapi.readshop.nl',
    teststag: 'https://teststagapi.readshop.nl',
    acc: 'https://accapi.readshop.nl',
    accstag: 'https://accstagapi.readshop.nl',
    prd: 'https://prdapi.readshop.nl',
    www: 'https://prdapi.readshop.nl',
    prdstag: 'https://prdstagapi.readshop.nl',
  },
  intershopId: 'tba-readshop_nl-Site',
  footer: {
    legalLinks: [
      { label: 'Algemene voorwaarden', url: 'https://klantenservice.readshop.nl/topic/2o2GLms3ofKrEDE9N/article/AhJ3DJXSFHcZq6CWf' },
      { label: 'Privacy', url: 'https://klantenservice.readshop.nl/topic/2o2GLms3ofKrEDE9N/article/8ofozqtJmFSEN7TRy' },
      { label: 'Cookies', url: '/cookiebeleid' },
      { label: 'Disclaimer', url: 'https://klantenservice.readshop.nl/topic/2o2GLms3ofKrEDE9N/article/vkHpQ9y44op8mrLou' },
    ],
    icons: [
      { key: 'ideal', url: 'https://klantenservice.readshop.nl/topic/PA2eRPAoSeFqsnwFB' },
      { key: 'mastercard', url: 'https://klantenservice.readshop.nl/topic/PA2eRPAoSeFqsnwFB' },
      { key: 'visa', url: 'https://klantenservice.readshop.nl/topic/PA2eRPAoSeFqsnwFB' },
    ],
  },
  header: {
    components: ['notificationBar', 'uspBar', 'masthead', 'navigationBar', 'communicationBar', 'saleBar'],
    hasMemberships: true,
    wishListUrl: '/mijn-account/wishlists',
    checkoutUrl: '/winkelmand',
    accountUrl: '/mijn-account',
    loginUrl: '/inloggen',
    accountMenu: [
      {
        id: 'overzicht',
        label: 'common.home.link', // overzicht
        url: '/mijn-account',
        ignoreInOverview: true,
      }, {
        id: 'gegevens',
        label: 'myenv.mainhub.profile-title', // gegevens
        description: 'myenv.mainhub.profile-desc',
        link: 'myenv.mainhub.profile-linktext',
      }, {
        id: 'bestellingen',
        label: 'myenv.mainhub.orders-title', // bestellingen
        description: 'myenv.mainhub.orders-desc',
        link: 'myenv.mainhub.orders-linktext',
      }, {
        id: 'ebooks',
        label: 'myenv.mainhub.ebooks-title', // ebooks
        description: 'myenv.mainhub.ebooks-desc',
        link: 'myenv.mainhub.ebooks-linktext',
      }, {
        id: 'wishlists',
        label: 'myenv.mainhub.wishlist-title', // verlanglijstje
        description: 'myenv.mainhub.wishlist-desc',
        link: 'myenv.mainhub.wishlist-linktext',
      }, {
        id: 'tegoeden',
        label: 'myenv.mainhub.credit-title', // toegoeden
        description: 'myenv.mainhub.credit-desc',
        link: 'myenv.mainhub.credit-linktext',
      },
    ],
    rating: {
      api: 'https://www.kiyoh.com/v1/review/feed.json?hash=joiu0fup3eww5hh&limit=1',
      responseAttribute: 'averageRating',
    },
    usp: {
      regular: [
        {
          icon: 'ico-check',
          text: 'header.usp1.text',
          link: 'header.usp1.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp2.text',
          link: 'header.usp2.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp3.text',
          link: 'header.usp3.link',
        },
      ],
    },
  },
  urls: {
    myAccount: '/mijn-account',
    ebooks: '/ebooks',
    customerService: 'https://klantenservice.readshop.nl/',
  },
  themeColors: {
    defaultCatHeaderBGColor: '#e7e7e7',
  },
  trackAndTraceUrl: 'https://jouw.postnl.nl/?CountryIso=NL#!/track-en-trace/[tracecode]/NL/[zipcode]',
  priceNotationLocale: 'nl-NL',
  iconMap: {
    'ico-usp-membership': 'ico-arrow-circle-right',
    'ico-usp-generic': 'ico-check',
    'ico-check-modern': 'ico-check',
    'ico-wishlist-header': 'ico-heart-outline',
    'ico-wishlist-product-add': 'ico-heart-outline',
    'ico-wishlist-product-added': 'ico-wishlist-added',
    'ico-user-header': 'ico-user-alt',
    'ico-cart': 'ico-shopping-bag',
    'ico-rating': 'ico-star',
  },
  features: {
    chat: {
      active: false,
    },
    shippingCosts: {
      active: true,
    },
    allowGiftcards: {
      active: false,
    },
    stockCheck: {
      active: true,
    },
    clickAndCollect: {
      active: true,
      data: {
        prefixLength: 4,
      },
    },
    reviewRating: {
      active: true,
      data: {
        url: 'https://www.kiyoh.com/v1/review/feed.json?hash=joiu0fup3eww5hh&limit=1',
        attr: 'averageRating',
        link: 'https://www.kiyoh.com/reviews/1066077/the_read_shop',
      },
    },
    usp: {
      active: true,
      data: {
        regular: [
          {
            icon: 'ico-usp-generic',
            text: 'membership.usp1.standard',
            link: 'membership.usp1.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp2.standard',
            link: 'membership.usp2.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp3.standard',
            link: 'membership.usp3.standard.link',
          },
        ],
        hasSecondaryLink: true,
      },
    },
    polly: {
      active: true,
      data: {
        id: 'EzowauGYhQvhYT3fX',
      },
    },
    storePickup: {
      active: true,
      data: {
        allowCoupons: true,
      },
    },
    priceLabel: {
      active: true,
    },
    membership: {
      active: true,
    },
    balanceChecker: {
      active: false,
    },
  },
  seo: {
    host: 'https://www.readshop.nl',
    defaultTitle: 'Boeken, eBooks, Kantoor, Tijdschriften, Cadeau',
    defaultDescription: 'ReadShop is dé gemakswinkel op het gebied van lezen, dagelijkse service, kantoor en cadeau.',
    defaultImagePath: 'https://www.readshop.nl/images/newsletter/social-readshop.png',
    appendix: '| ReadShop',
    descriptionAppendix: '✓ Voor 23:00 besteld, morgen in huis! ✓ Gratis verzending vanaf 20,- ✓ Gratis afhalen in de winkel',
    alternates: [],
    fbAppId: '',
    twitterHandle: '@thereadshop',
    organization: {
      name: 'ReadShop',
      description: 'ReadShop is een laagdrempelige gemakswinkel met een ruim en uitgebalanceerd assortiment tijdschriften, wenskaarten, kantoorartikelen, boeken en een sterke groei in serviceproducten.',
      url: 'https://www.readshop.nl',
      areaServed: 'NL',
      telephone: '+31881338432',
      logo: 'https://www.readshop.nl/client/assets/organization-logo.png',
      sameAs: [
        'https://www.facebook.com/TheReadShop',
        'https://twitter.com/thereadshop',
      ],
      email: 'helpdesk@readshop.nl',
    },
  },
  textVariables,
  adyen: {
    test: 'test_UQH7CSQLHVCWZOTER4TOWMBLWAM6EVPW',
    live: 'live_GXKJ2IVUQFC5FEKREJXGULKTEEPGV7Z4',
  },
  checkout: {
    paymentMethodGiftcard: 'checkout.payment.actioncode-voucher',
  },
  filters: {
    storeStockAvailable: 'storeswithstock_rs_nl',
  },
};

module.exports = channelConfig;
